.main-image {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    opacity: 1;
    -webkit-transition: all 2s ease-out; 
    -moz-transition: all 2s ease-out; 
    -ms-transition: all 2s ease-out; 
    -o-transition: all 2s ease-out; 
}


.fadeIn {
    animation: 2s fadeIn forwards;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
  
.fadeOut {
    animation: 2s fadeOut forwards;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}


@keyframes fadeIn {
    from {
      opacity: 0;
      /*transform: translate(-20px, 0);*/
    }
    to {
      opacity: 1;
      /*transform: translate(0px, 0px);*/
    }
  }

@keyframes fadeOut {
from {
    opacity: 1;
    /*transform: translate(0px, 0px);*/
}
to {
    /*transform: translate(-20px, 0);*/
    opacity: 0;
}
}